import React from 'react';
import DesktopMenu from './Desktop';
import MobileMenu from './Mobile';

const Menu = () => (
  <>
    <DesktopMenu />
    <MobileMenu />
  </>
);

export default Menu;
